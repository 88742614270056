<template>
  <v-container
    id="financeur-detail"
    fluid
    tag="section"
  >
    <template>
      <contact-detail
        ref="contactDetailRef"
      />
    </template>
    <v-row justify="center">
      <v-col
        cols="12"
        md="7"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-3">
              {{ financeur.nomCommercial }}
            </div>
          </template>

          <v-card-text class="text-center">
            <v-simple-table
              id="table-financeur-detail"
            >
                <tbody>
                  <tr
                    v-for="item in items"
                    :key="item.name"
                  >
                    <td
                      class="text-left"
                      width="1%"
                    >
                      <v-icon>{{ item.icon }}</v-icon>
                    </td>
                    <th
                      class="text-left"
                      width="25%"
                    >
                      {{ item.title }}
                    </th>
                    <td
                      class="text-left"
                      v-html="item.value"
                    ></td>
                  </tr>
                </tbody>
            </v-simple-table>
          </v-card-text>
        </base-material-card>

        <base-material-card
          v-if="factures"
          id="card-factures-financeur"
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Factures associées ({{ factures.length }})
            </div>
          </template>

          <v-row>
            <v-col
              cols="12"
            >
              <v-data-table
                id="table-factures"
                :headers="headersFactures"
                :items="factures"
                :items-per-page="5"
                :sort-by="'numero'"
                :sort-desc="[true]"
                @click:row="detailFacture"
              >
                <template v-slot:[`item.envoyee`]="{ item }">
                  <v-icon
                    class="ml-1"
                    color="green"
                    v-if="item.envoyee"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    class="ml-1"
                    color="red"
                    v-if="!item.envoyee"
                  >
                    mdi-close-circle
                  </v-icon>
                </template>
                <template v-slot:[`item.payee`]="{ item }">
                  <v-icon
                    class="ml-1"
                    color="green"
                    v-if="item.payee"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    class="ml-1"
                    color="red"
                    v-if="!item.payee"
                  >
                    mdi-close-circle
                  </v-icon>
                </template>
                <template v-slot:[`item.dateEmission`]="{ item }">
                  {{ new Date(item.dateEmission).toLocaleString().substring(0, 10) }}
                </template>
                <template v-slot:[`item.montantHT`]="{ item }">
                  {{ toEuro(item.montantHT) }}
                </template>
                <template v-slot:[`item.montantTVA`]="{ item }">
                  {{ toEuro(item.montantTVA) }}
                </template>
                <template v-slot:[`item.montantTotal`]="{ item }">
                  {{ toEuro(item.montantTotal) }}
                </template>
                <template v-slot:[`item.lienFacture`]="{ item }">
                  <v-icon
                    v-if="item.lienFacture"
                    @click.stop="downloadFacture(item.reference)"
                  >
                    mdi-download
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="5"
      >
        <base-material-card
          class="text-center"
          color="primary"
        >
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Actions
            </div>
          </template>
          <v-card-text>
            <v-btn
              color="blue"
              rounded
              class="ma-2"
              @click="$router.push({ name: 'ModifierFinanceur' , params: {id: financeur.id}})"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Modifier
            </v-btn>
          </v-card-text>
        </base-material-card>

        <base-material-card
          id="card-contacts-administratifs"
          color="primary"
          title="Contacts"
        >
          <v-row>
            <v-col
              cols="12"
              v-for="contact in financeur.contacts"
              :key="contact.id"
            >
              <v-card
                elevation="2"
                class="px-3 py-2 ma-1"
                @click="$refs.contactDetailRef.show(contact, financeur)"
              >
                <v-card-title
                  class="mb-1"
                >
                  {{ contact.sexe ? titreItems.find(item => item.sexe === contact.sexe).titre : '' }} {{ contact.prenom }} {{ contact.nom }}
                </v-card-title>
                <v-card-text
                  class="contact-email-phone"
                >
                  <p>
                    <v-chip
                      class="mr-2"
                      v-for="typeContact of displayTypesContact(contact.types)"
                      :key="typeContact"
                      input-value="false"
                      small
                    >
                      {{ typeContact }}
                    </v-chip>
                  </p>
                  <p v-if="contact.telephonePro">
                    <span><v-icon class="mr-2">mdi-phone</v-icon> {{ contact.telephonePro }}</span>
                  </p>
                  <p v-if="contact.email">
                    <v-icon class="mr-2">mdi-email</v-icon> {{ contact.email }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
            class="pa-6"
              cols="12"
              md="4"
            >
              <v-btn
                id="btnAjouterContact"
                small
                color="gray"
                @click="$refs.contactDetailRef.create(financeur, financeur.nature)"
              >
                <v-icon
                  class="mr-2"
                  dark
                >
                  mdi-plus-circle
                </v-icon>
                Ajouter un contact
              </v-btn>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapMutations } from 'vuex';

  export default {

    components: {
      ContactDetail: () => import('../../component/ContactDetail'),
    },

    data() {
      return {
        headersFormations: [
          {
            sortable: true,
            text: 'Nom',
            value: 'nom',
          },
        ],
        headersFactures: [
          {
            sortable: true,
            text: 'Réf.',
            value: 'reference',
          },
          {
            sortable: true,
            text: 'Date',
            value: 'dateEmission',
          },
          {
            sortable: false,
            text: 'Envoyée',
            value: 'envoyee',
          },
          {
            sortable: false,
            text: 'Payée',
            value: 'payee',
          },
          {
            sortable: false,
            text: 'Montant HT',
            value: 'montantHT',
            align: 'right',
          },
          {
            sortable: false,
            text: 'TVA',
            value: 'montantTVA',
            align: 'right',
          },
          {
            sortable: false,
            text: 'Total TTC',
            value: 'montantTotal',
            align: 'right',
          },
          {
            sortable: false,
            text: 'PDF',
            value: 'lienFacture',
          },
        ],
        items: [],
        financeur: {},
        factures: [],
        titreItems: [
          {
            titre: 'M.',
            sexe: 'm',
          },
          {
            titre: 'Mme',
            sexe: 'f',
          },
        ],
      }
    },

    created () {
      let displayType = '';
      // const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'Europe/Paris' };

      this.axios.get('/financeurs/' + this.$route.params.id)
        .then((res) => {
          this.financeur = res.data;
          switch (this.financeur.type) {
            case 'OPCO':
              displayType = '<span id="financeur-type" class="badge-chips badge-yellow">OPCO</span>';
              break;
            case 'POLE_EMPLOI':
              displayType = '<span id="financeur-type" class="badge-chips badge-gray">Pôle Emploi</span>';
              break;
            case 'CPF':
              displayType = '<span id="financeur-type" class="badge-chips badge-blue">CPF</span>';
              break;
            case 'AUTRE':
              displayType = '<span id="financeur-type" class="badge-chips badge-purple">Autre</span>';
              break;
          }

          this.items = [
            {
              icon: 'mdi-office-building',
              title: 'Nom',
              value: this.financeur.nomCommercial,
            },
            {
              icon: 'mdi-barcode-scan',
              title: 'Code facture',
              value: this.financeur.codeFacture,
            },
            {
              icon: 'mdi-flare',
              title: 'Type',
              value: displayType,
            },
            {
              icon: 'mdi-phone',
              title: 'Téléphone',
              value: this.financeur.telephone ? this.formatPhone(this.financeur.telephone) : '',
            },
            {
              icon: 'mdi-at',
              title: 'E-mail',
              value: this.financeur.email ?? '',
            },
            {
              icon: 'mdi-map-marker-outline',
              title: 'Adresse',
              value: this.financeur.adresse ? this.formatAdresse(this.financeur.adresse) : '',
            },
            {
              icon: 'mdi-ticket-confirmation-outline',
              title: 'N° SIREN',
              value: this.financeur.siren ?? '',
            },
            {
              icon: 'mdi-ticket-confirmation-outline',
              title: 'N° SIRET',
              value: this.financeur.siret ?? '',
            },
          ];
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération du financeur', type: 'warn' });
        });

      this.axios.get('/facturesSortantes')
        .then((res) => {
          this.factures = res.data.filter(f => f.destinataire.id === this.financeur.id);
        })
        .catch(e => {
          this.setSnack({ text: 'Erreur lors de la récupération des factures', type: 'warn' });
        });
    },

    methods: {
      detailFacture(item, row) {
        this.$router.push({ name: 'FactureDetail', params: { id: item.id } });
      },

      displayTypesContact(types) {
        const values = {
          ADMINISTRATIF: 'Contact administratif',
          COMMERCIAL: 'Contact commercial',
          FACTURATION: 'Contact facturation',
        };

        return types.map(type => values[type]);
      },

      formatPhone(number) {
        return number.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ' ');
      },

      formatAdresse(adresse) {
        let formattedAdresse = '';
        formattedAdresse += adresse.adressePostale ? adresse.adressePostale + '<br>' : '';
        formattedAdresse += adresse.codePostal ? adresse.codePostal + ' ' : '';
        formattedAdresse += adresse.ville ?? '';
        formattedAdresse += adresse.pays ? ', ' + adresse.pays + ' ' : '';
        return formattedAdresse;
      },

      downloadFacture(id) {
        this.axios.get('/facturesSortantes/' + id + '/getPdf', { responseType: 'blob' })
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]), { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Facture' + '.pdf');
            document.body.appendChild(link);
            link.click();
          })
          .catch(e => {
            this.setSnack({ text: 'Erreur lors du téléchargement de la facture', type: 'warn' });
          });
      },

      toEuro(num) {
        if (isNaN(num)) {
          num = 0;
        }
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(num);
      },

      ...mapMutations({
        setSnack: 'SET_SNACK',
      }),
    },
  }

</script>

<style scoped>
  .text-center {
    text-align: center;
  }

  #table-financeur-detail th, #table-financeur-detail td {
    font-size: 1rem;
  }

  .badge-chips {
    padding: 6px 12px;
    font-weight: 600;
    border-radius: 4px;
  }

  .badge-yellow {
    border: solid 1px #c98d17;
    color: #c98d17;
  }

  .badge-gray {
    border: solid 1px #797b86;
    color: #797b86;
  }

  .badge-gray {
    border: solid 1px #9657af;
    color: #9657af;
  }

  .badge-blue {
    border: solid 1px #082c6f;
    color: #082c6f;
  }

  .bloc-facture {
    border: solid 1px #e0e1e0;
    padding: 10px;
    border-radius: 4px;
  }

  .no-underline {
    text-decoration: none;
  }

  #table-factures tbody tr:not(.v-row-group__header) {
    cursor: pointer;
    transition: background-color 0.1s;
  }

  #table-factures tbody tr:not(.v-row-group__header):hover {
    background-color: #f8f8f8;
    font-weight: 400 !important;
  }

  .v-card--link:before {
    border-radius: 30px;
  }

  .contact-email-phone {
    font-size: 0.8em;
  }

  .contact-email-phone .v-icon.v-icon {
    font-size: 14px;
  }
</style>
